import { Outlet } from "react-router-dom";
import { Sidebar } from "../components/sidebar";
import { Header } from "../components/header";

const HomePage = () => {
  return (
    <>
      <div className="w-full layout-wrapper active">
        <div className="w-full flex relative">
          <Sidebar />
          {/* <div
            style={{ zIndex: 25 }}
            className="aside-overlay block sm:hidden w-full h-full fixed left-0 top-0 bg-black bg-opacity-30"
          ></div> */}
          <div className="body-wrapper dark:bg-darkblack-500 flex-1 overflow-x-hidden" style={{minHeight: '100vh'}}>
            <Header />
            <main className="w-full xl:px-12 px-6 pb-6 xl:pb-12 sm:pt-[156px] pt-[100px]">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
