import React, { createContext, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouteGuard } from "react-guardian";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";

import { Store } from "@medusajs/medusa";
import Medusa from "@medusajs/medusa-js";
import { QueryClient } from "@tanstack/react-query";
import { MedusaProvider } from "medusa-react";
import { loggedInOnlyPolicy } from "./policies";
import HomePage from "./routes/home";
import LoginPage from "./routes/login";
import DepositHistoryTable from "./routes/plans_fragments/components/deposit_history";
import WithdrawalRequestsTable from "./routes/plans_fragments/components/withdrawal_requests_history";
import StatementsPage from "./routes/statements";
import { BASE_URL } from "./utils";
import FundCustomerPage from "./routes/plans_fragments/fund_customer_page";
import RewardCustomerPage from "./routes/plans_fragments/reward_customer_page";
import MobileMenu from "./components/mobile_menu";

const medusa = new Medusa({
  baseUrl: BASE_URL,
  maxRetries: 2,
});

const queryClient = new QueryClient();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<Root />}
      id="root"
      loader={async (params) => {
        const req = await fetch(`${BASE_URL}store/domain/info`);

        if (req.status >= 400 && req.status < 600) {
          const { message }: { message: string } = await req.json();
          throw new Error(message);
        }

        const { store }: { store: Store } = await req.json();

        return { store };
      }}
    >
      <Route
        path="home"
        element={
          <RouteGuard policies={[loggedInOnlyPolicy]}>
            <HomePage />
          </RouteGuard>
        }
        children={[
          <Route
            path="statement/*"
            element={<StatementsPage />}
            children={[
              <Route
                path="deposits"
                element={
                  <>
                    <DepositHistoryTable />
                  </>
                }
              />,
              <Route
                path="fund"
                element={
                  <>
                    <FundCustomerPage />
                  </>
                }
              />,
              <Route
                path="reward"
                element={
                  <>
                    <RewardCustomerPage />
                  </>
                }
              />,
              <Route
                path="withdrawals"
                element={<WithdrawalRequestsTable />}
              />,
              <Route index element={<Navigate to="deposits" />} />,
            ]}
          ></Route>,
          
          <Route index element={<MobileMenu/>} />,,
        ]}
      />

      <Route path="login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/login" replace={true} />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const LoadingContext = createContext<{
  loading: boolean;
  setLoading?: (loading: boolean) => void;
}>({ loading: false });

const App = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      <link
        rel="stylesheet"
        href="/css/font-awesome-all.min.css"
        type="text/css"
      />

      <React.StrictMode>
        <MedusaProvider
          medusaClient={medusa}
          queryClientProviderProps={{ client: queryClient }}
          baseUrl={BASE_URL}
        >
          <LoadingContext.Provider
            value={{ loading: loading, setLoading: setLoading }}
          >
            <RouterProvider router={router} />
          </LoadingContext.Provider>
        </MedusaProvider>
      </React.StrictMode>
    </>
  );
};

root.render(<App></App>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
