import { Outlet, useNavigation } from "react-router-dom";
import { Sidebar } from "../components/sidebar";
import { LoadingDialog } from "../components/loading_dialog";
import { useContext } from "react";
import { LoadingContext } from "..";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export default function Root() {
  const { state: navigationState } = useNavigation();

  const { loading } = useContext(LoadingContext);

  return (
    <>
      {/* <!-- Preloader --> */}

      <Outlet />
      <LoadingDialog isOpen={navigationState === "loading" ||navigationState === "submitting" || loading} />
      <ToastContainer />
    </>
  );
}
