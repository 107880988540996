import React, { ReactNode } from "react";
import DashboardIcon from "../icons/dashboard.icon";
import HistoryIcon from "../icons/history.icon";
import IntegrationIcon from "../icons/integration.icon";
import LogoutIcon from "../icons/logout.icon";
import TransactionIcon from "../icons/transaction.icon";
import UserIcon, { CaretIcon, MoneyBagIcon } from "../icons/user.icon";
import { Link, Navigate } from "react-router-dom";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils/envConfig";

export class Sidebar extends React.Component<
  {},
  { logoutSuccessful: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      logoutSuccessful: false,
    };
  }
  componentDidMount(): void {
    //drawer
    $(".sidebar-wrapper .sidebar-body li.item a").on("click", () => {
      const checkClassExits = $(".layout-wrapper");
      if (!checkClassExits.hasClass("active")) {
        checkClassExits.addClass("active");
      }
    });
  }
  render() {
    const group: {
      label: string;
      children: {
        label: string;
        icon: ReactNode;
        path?: string;
        action?: () => void;
      }[];
    }[] = [
      {
        label: "Statements",
        children: [
          {
            label: "Deposits",
            icon: <IntegrationIcon />,
            path: "/home/statement/deposits",
          },
          {
            label: "Fund Account",
            icon: <MoneyBagIcon />,
            path: "/home/statement/fund",
          },
          {
            label: "Reward Account",
            icon: <MoneyBagIcon />,
            path: "/home/statement/reward",
          },
          {
            label: "Withdrawal Requests",
            icon: <TransactionIcon />,
            path: "/home/statement/withdrawals",
          },
        ],
      },
      {
        label: "Other",
        children: [
          {
            label: "Logout",
            icon: <LogoutIcon />,
            action: () => {
              localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
              this.setState({ logoutSuccessful: true });
            },
          },
        ],
      },
    ];
    return (
      <>
        <aside className="block xl:block sm:hidden sidebar-wrapper w-[308px] fixed top-0 bg-white dark:bg-darkblack-600 h-full z-30">
          <div className="sidebar-header relative border-r border-b   border-r-[#F7F7F7] border-b-[#F7F7F7] dark:border-darkblack-400 w-full h-[108px] flex items-center pl-[50px] z-30">
            <a href="index.html">
              <div className="bg-white rounded-md px-4 py-2">
                <img src="/images/logo/logo.png" className="w-[136px]" />
              </div>
            </a>
            <button
              type="button"
              className="drawer-btn absolute right-0 top-auto"
              title="Ctrl+b"
              onClick={() => {
                $(".layout-wrapper").toggleClass('active');
              }}
            >
              <span>
                <CaretIcon />
              </span>
            </button>
          </div>
          <div className="sidebar-body pl-[48px] pt-[14px] w-full relative z-30 h-screen overflow-style-none overflow-y-scroll pb-[200px]">
            <div className="nav-wrapper pr-[50px] mb-[36px]">
              {group.map((item, index) => (
                <div key={index} className="item-wrapper mb-5">
                  <h4 className="text-sm font-medium dark:text-bgray-50 text-bgray-700 border-b dark:border-darkblack-400 border-bgray-200 leading-7">
                    {item.label}
                  </h4>

                  <ul className="mt-2.5">
                    {item.children.map((childItem, index) => (
                      childItem.action ? (
                        <li
                          key={index}
                          className="item py-[11px] text-bgray-900 dark:text-white"
                        >
                        <div 
                        onClick={childItem.action}
                        className="flex items-center justify-between">
                            <div className="flex space-x-2.5 items-center">
                              <span className="item-ico">{childItem.icon}</span>
                              <span className="item-text text-lg font-medium leading-none">
                                {childItem.label}
                              </span>
                            </div>
                          </div>
                          </li>
                      ) :
                      <li
                        key={index}
                        className="item py-[11px] text-bgray-900 dark:text-white"
                      >
                        <Link to={childItem.path!}>
                          <div className="flex items-center justify-between">
                            <div className="flex space-x-2.5 items-center">
                              <span className="item-ico">{childItem.icon}</span>
                              <span className="item-text text-lg font-medium leading-none">
                                {childItem.label}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="upgrade-wrapper w-full h-[172px] pr-[24px] mb-[26px]">
              <div
                className="w-full h-full rounded-lg upgrade-banner relative"
                style={{
                  backgroundImage: "url(/images/bg/upgrade-bg.png)",
                }}
              >
                <div
                  style={{ left: "calc(50% - 20px)", top: "-20px" }}
                  className="w-10 flex justify-center items-center h-10 rounded-full bg-success-300 border border-white absolute"
                >
                  <span>
                    <MoneyBagIcon />
                  </span>
                </div>
                <h1 className="font-bold text-white text-xl text-center pt-8 mb-2">
                  Need Help?
                </h1>
                <p className="text-sm leading-5 text-white opacity-[0.5] text-center px-7 mb-2">
                  Contact our 24/7 customer support center
                </p>
              </div>
            </div>
            <div className="copy-write-text">
              <p className="text-sm text-[#969BA0]">
                © 2024 All Rights Reserved
              </p>
              <p className="text-sm text-bgray-700 font-medium">
                ZenithInvestments
              </p>
            </div>
          </div>
        </aside>
        {/* <div
          style={{ zIndex: 25 }}
          className="aside-overlay block sm:hidden w-full h-full fixed left-0 top-0 bg-black bg-opacity-30"
        ></div> */}
        <aside className="sm:block hidden relative w-[96px] bg-white dark:bg-black">
          <div className="w-full sidebar-wrapper-collapse relative top-0 z-30">
            <div className="sidebar-header bg-white dark:bg-darkblack-600 dark:border-darkblack-500 sticky top-0 border-r border-b border-r-[#F7F7F7] border-b-[#F7F7F7] w-full h-[108px] flex items-center justify-center z-20">
              <img src="/images/logo/favicon.png" className="w-[48px] p-2" style={{position: 'fixed'}} />
            </div>
            <div className="sidebar-body pt-[14px] w-full">
              <div className="flex flex-col items-center">
                <div className="nav-wrapper mb-[36px]" style={{position: 'fixed'}}>
                  {group.map((item, index) => (
                    <div key={index} className="item-wrapper mb-5">
                      <ul className="mt-2.5 flex justify-center items-center flex-col">
                        {item.children.map((childItem, index) =>
                          childItem.action ? (
                            <li
                              key={index}
                              className="item py-[11px] px-[43px]"
                              onClick={childItem.action}
                            >
                              <span className="item-ico">{childItem.icon}</span>
                            </li>
                          ) : (
                            <li
                              key={index}
                              className="item py-[11px] px-[43px]"
                            >
                              <Link to={childItem.path!}>
                                <span className="item-ico">
                                  {childItem.icon}
                                </span>
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  ))}
                </div>
                <div className="upgrade-wrapper" style={{position: 'fixed', bottom: '10px'}}>
                  <div className="w-10 flex justify-center items-center h-10 rounded-full bg-success-300 border border-white">
                    <span>
                      <MoneyBagIcon />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>
        {this.state.logoutSuccessful && <Navigate to="/login" />}
      </>
    );
  }
}
