import { yupResolver } from "@hookform/resolvers/yup";
import { useMedusa , useMeCustomer} from "medusa-react";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import * as yup from "yup";
import { LoadingContext } from "..";
import { EyeIcon } from "../icons/user.icon";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils/envConfig";
import { toast } from "react-toastify";

export type LoginFormType = {
  email: string;
  password: string;
};

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const LoginPage = () => {
  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormType>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const { client: medusaClient } = useMedusa();
  const { refetch: refetchCustomer } = useMeCustomer()
  const [state, setState] = useState({
    loginSuccessful: false,
    obscurePass: true,
  });

  const { setLoading } = useContext(LoadingContext);

  const validateSignIn = async (data: LoginFormType) => {
    setState({ ...state });
    setLoading!(true);
    medusaClient.admin.auth
      .getToken(data)
      .then((res) => {
        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, res.access_token);
        setState({ ...state, loginSuccessful: true });
        setLoading!(false);
      })
      .catch((err) => {
        setState({ ...state, loginSuccessful: false });
        toast.error(err?.response?.data?.message ?? "Invalid Credentials");
        setLoading!(false);
      });
  };

  return (
    <>
      <section className="w-full bg-white dark:bg-darkblack-500">
        <div className="flex flex-col lg:flex-row justify-between min-h-screen">
          {/* <!-- Left --> */}
          <div className="px-5 xl:pl-12 pt-10 m-auto">
            <header className="flex justify-center flex-row">
              {/* <!--              page-title--> */}
              <div className="bg-white rounded-md">
                <img src="/images/logo/logo.png" className="w-[136px] px-4 py-2" />
              </div>
            </header>
            <div className="max-w-[450px] m-auto pt-24 pb-16">
              <header className="text-center mb-8">
                <h2 className="text-bgray-900 dark:text-white text-4xl font-semibold font-poppins mb-2">
                  Sign in to ZenithInvestments.
                </h2>
                <p className="font-urbanis text-base font-medium text-bgray-600 dark:text-bgray-50">
                  Admin
                </p>
              </header>
              <form onSubmit={handleSubmit(validateSignIn)}>
                <div className="mb-4">
                  <input
                    type="text"
                    className="text-bgray-800 text-base border border-bgray-300 dark:border-darkblack-400 dark:bg-darkblack-500 dark:text-white h-14 w-full focus:border-success-300 focus:ring-0 rounded-lg px-4 py-3.5 placeholder:text-bgray-500 placeholder:text-base"
                    placeholder="Email"
                    {...register("email", { required: true })}
                  />
                </div>
                <div className="mb-6 relative">
                  <input
                    type={state.obscurePass ? "password" : "text"}
                    className="text-bgray-800 text-base border border-bgray-300 dark:border-darkblack-400 dark:bg-darkblack-500 dark:text-white h-14 w-full focus:border-success-300 focus:ring-0 rounded-lg px-4 py-3.5 placeholder:text-bgray-500 placeholder:text-base"
                    placeholder="Password"
                    {...register("password", { required: true, minLength: 4 })}
                  />
                  <button
                    type="button"
                    onClick={() =>
                      setState({
                        ...state,
                        obscurePass: !state.obscurePass,
                      })
                    }
                    className="absolute top-4 right-4 bottom-4"
                  >
                    <EyeIcon />
                  </button>
                </div>
                {(errors.email?.message || errors.password?.message) && (
                  <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                    {errors.email?.message ?? errors.password?.message}
                  </p>
                )}

                <button
                  type="submit"
                  className="py-3.5 flex items-center justify-center text-white font-bold bg-blue-500 hover:bg-blue-400 transition-all rounded-lg w-full"
                >
                  Sign In
                </button>
              </form>
              <p className="text-bgray-600 dark:text-white text-center text-sm mt-6">
                @ 2024 ZenithInvestments. All Right Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>

      {state.loginSuccessful && <Navigate to="/home/" replace={true} />}
    </>
  );
};

export default LoginPage;
