import { ReactNode } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { AvailablePlansFlatIcon, FAQIcon, MoneyBagIcon, MyPlansFlatIcon, SecurityIcon, UserIconFlat } from "../icons/user.icon";

const StatementsPage = () => {
  const group: {
    label: string;
    subtitle?: string;
    icon: ReactNode;
    path?: string;
  }[] = [
    {
      label: "Deposits",
      subtitle: "Deposit history",
      icon: <AvailablePlansFlatIcon />,
      path: "/home/statement/deposits",
    },
    {
      label: "Fund",
      subtitle: "Fund customer account",
      icon: <MoneyBagIcon />,
      path: "/home/statement/fund",
    },
    {
      label: "Reward",
      subtitle: "Reward customer account",
      icon: <MoneyBagIcon />,
      path: "/home/statement/reward",
    },
    {
      label: "Withdrawal Requests",
      subtitle: "Withdrawal history",
      icon: <MyPlansFlatIcon />,
      path: "/home/statement/withdrawals",
    },
  ];

  return (
    <>
      {/* <!-- write your code here--> */}
      <div className="grid grid-cols-1 xl:grid-cols-12 bg-white dark:bg-darkblack-600 rounded-xl">
        {/* <!-- Sidebar --> */}
        <aside className="hidden md:block col-span-3 border-r border-bgray-200 dark:border-darkblack-400">
          {/* <!-- Sidebar Tabs --> */}

          <div className="px-4 py-6">
            {group.map((item, index) => (
              <NavLink to={item.path!} key={index}>
                {({ isActive, isPending }) => (
                  <div
                    key={index}
                    className={`tab ${
                      isActive ? "active" : ""
                    } flex gap-x-4 p-4 rounded-lg transition-all`}
                    data-tab="tab1"
                  >
                    <div className="w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100 dark:bg-darkblack-500">
                      {item.icon}
                    </div>
                    <div>
                      <h4 className="text-base font-bold text-bgray-900 dark:text-white">
                        {item.label}
                      </h4>
                      {item.subtitle && (
                        <p className="text-sm font-medium text-bgray-700 dark:text-darkblack-300 mt-0.5">
                          {item.subtitle}
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
        </aside>

        {/* <!--Tab Content --> */}
        <div className="py-8 px-10 col-span-9 tab-content">
          <div className="tab-pane active">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default StatementsPage;
