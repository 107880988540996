import { Policy, RoutePolicy } from "react-guardian";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils/envConfig";

const loggedInOnlyPolicy: Policy = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
  if (token)
    return {
      authorized: true,
    };
  return {
    authorized: false,
    redirect: "/login",
  };
};

export { loggedInOnlyPolicy };
