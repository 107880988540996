import { toast } from "react-toastify";
import { BASE_URL, Withdrawal } from "../../../utils";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../../utils/envConfig";

const WithdrawalContextMenu = (props: {
  withdrawal: Withdrawal;
  refresh: () => void;
}) => {
  const changeWithdrawalStatus = (approved: boolean) => {
    fetch(
      false
        ? `${BASE_URL}admin/withdrawals/approve/${props.withdrawal.id}`
        : `${BASE_URL}admin/withdrawals/reject/${props.withdrawal.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY
          )}`,
        },
      }
    )
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }
        toast.success(approved ? "Withdrawal Approved" : "Withdrawal Rejected");
        props.refresh();
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      });
  };

  return (
    <div
      id="cardsOptions"
      className="rounded-lg shadow-lg min-w-[150px] bg-white dark:bg-darkblack-500 right-0 z-10 top-full  overflow-hidden"
    >
      <ul>
        <li
          onClick={() => changeWithdrawalStatus(false)}
          className="text-sm text-bgray-900 cursor-pointer px-5 py-2 hover:bg-bgray-100 hover:dark:bg-darkblack-600 dark:text-white font-semibold"
        >
          Reject
        </li>
      </ul>
    </div>
  );
};

export default WithdrawalContextMenu;
