import { reduce } from "lodash";
import { Link, NavLink, Navigate } from "react-router-dom";
import { ReactNode, useState } from "react";
import UserIcon, { AvailablePlansFlatIcon, FAQIcon, MoneyBagIcon, MyPlansFlatIcon, SecurityIcon, UserIconFlat } from "../icons/user.icon";
import IntegrationIcon from "../icons/integration.icon";
import LogoutIcon from "../icons/logout.icon";
import TransactionIcon from "../icons/transaction.icon";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../utils/envConfig";

export const MENU_GROUPS: {
    label: string;
    children: {
      label: string;
      icon: ReactNode;
      path?: string;
      action?: (ctx: React.Component) => void;
    }[];
  }[] = [
    {
      label: "Statements",
      children: [
        {
          label: "Deposits",
          icon: <IntegrationIcon />,
          path: "/home/statement/deposits",
        },
        {
          label: "Fund Account",
          icon: <MoneyBagIcon />,
          path: "/home/statement/fund",
        },
        {
          label: "Reward Account",
          icon: <MoneyBagIcon />,
          path: "/home/statement/reward",
        },
        {
          label: "Withdrawal Requests",
          icon: <TransactionIcon />,
          path: "/home/statement/withdrawals",
        },
      ],
    },
    {
      label: "Other",
      children: [
        {
          label: "Logout",
          icon: <LogoutIcon />,
          action: (ctx: React.Component) => {
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
            ctx.setState({ logoutSuccessful: true });
          },
        },
      ],
    },
  ];
const MobileMenu = () => {
  const [state, setState] = useState<{
    logoutSuccessful: boolean;
  }>({
    logoutSuccessful: false,
  });

  return (
    <div className="gap-y-4 flex flex-col">
      <div className="grid grid-cols-3 bg-white dark:bg-darkblack-600 rounded-xl px-4 py-6">
        {reduce(
          MENU_GROUPS.map((group) => group.children),
          (a, b) => [...a, ...b]
        )?.map((item, index) => (
          <>
            {item.action ? (
              <div
                key={index}
                onClick={() => item.action!({ setState } as any)}
                className={`tab flex flex-col gap-y-4 p-4 rounded-lg transition-all`}
                data-tab="tab1"
              >
                <div className="mx-auto w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100 dark:bg-darkblack-500">
                  {item.icon}
                </div>
                <div>
                  <h4 className="text-center text-base font-bold text-bgray-900 dark:text-white">
                    {item.label}
                  </h4>
                </div>
              </div>
            ) : (
              <NavLink to={item.path!} key={index}>
                {({ isActive, isPending }) => (
                  <div
                    key={index}
                    className={`tab ${
                      isActive ? "active" : ""
                    } flex flex-col gap-y-4 p-4 rounded-lg transition-all`}
                    data-tab="tab1"
                  >
                    <div className="mx-auto w-12 tab-icon transition-all h-12 shrink-0 rounded-full inline-flex items-center justify-center bg-bgray-100 dark:bg-darkblack-500">
                      {item.icon}
                    </div>
                    <div>
                      <h4 className="text-center text-base font-bold text-bgray-900 dark:text-white">
                        {item.label}
                      </h4>
                    </div>
                  </div>
                )}
              </NavLink>
            )}
          </>
        ))}
        
      </div>
      

      {state.logoutSuccessful && <Navigate to="/login" />}
      
      <div className="copy-write-text mx-auto text-center">
              <p className="text-sm text-[#969BA0]">
                © 2024 All Rights Reserved
              </p>
              <p className="text-sm text-bgray-700 font-medium">
                ZenithInvestments
              </p>
            </div>
    </div>
    
  );
};

export default MobileMenu;
