import { Outlet, useRouteLoaderData } from "react-router-dom";
import { EyeIcon } from "../../icons/user.icon";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMedusa } from "medusa-react";
import { useContext, useMemo, useState } from "react";
import { LOCAL_STORAGE_ACCESS_TOKEN_KEY } from "../../utils/envConfig";
import { ToastContainer, toast } from "react-toastify";
import { LoadingContext } from "../..";
import { Customer, Store } from "@medusajs/medusa";
import { Account, BASE_URL, StoreDepositAddress } from "../../utils";
import AsyncSelect from "react-select/async";

export type ChangePasswordFormType = {
  amount: number;
  currency: string;
  customerId: string;
};

const FundCustomerPage = () => {
  const schema = yup
    .object({
      amount: yup.number().required(),
      currency: yup.string().oneOf(["ETH", "BTC", "USDT"]).required(),
      customerId: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ChangePasswordFormType>({
    defaultValues: {} as any,
    resolver: yupResolver(schema as any),
  });

  const { client: medusaClient } = useMedusa();
  const { setLoading } = useContext(LoadingContext);

  const [state, setState] = useState<{
    currency?: string;
    customer?: { value: string; label: string; accounts?: Account[] };
  }>({
    currency: "USDT",
    customer: undefined,
  });

  const { store } = useRouteLoaderData("root") as { store: Store };
  const lookupCustomers = (
    inputValue: string,
    callback: (options: { value: string; label: string }[]) => void
  ) => {
    fetch(`${BASE_URL}admin/lookup/customers?q=${inputValue}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    }).then(async (res) => {
      if (res.status >= 400 && res.status < 600) {
        const { message }: { message: string } = await res.json();
        toast.error(message);
        callback([]);
        return;
      }

      const {
        customers,
      }: { customers: (Customer & { accounts: Account[] })[] } =
        await res.json();
      callback(
        customers.map((customer) => ({
          label: `${customer.last_name ?? ""} ${
            customer.first_name ?? ""
          }`.trim(),
          value: customer.id,
          accounts: customer.accounts,
        }))
      );
    });
  };

  const fundAccount = async (data: ChangePasswordFormType) => {
    console.error(data);

    // create the deposit and dispose
    fetch(`${BASE_URL}admin/deposits/fund`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          LOCAL_STORAGE_ACCESS_TOKEN_KEY
        )}`,
      },
    })
      .then(async (response) => {
        if (response.status >= 400 && response.status < 600) {
          const { message }: { message: string } = await response.json();
          throw new Error(message);
        }

        toast.success("Account funded successfully");

        reset();
        setState({ currency: undefined, customer: undefined });
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.message ?? "An error occured");
      });
  };
  return (
    <>
      <div className="flex flex-col gap-10 xl:flex-row xl:items-center">
        <div className="grow max-w-[614px]">
          <h3 className="text-2xl font-bold text-bgray-900 dark:text-white mb-3">
            Funding
          </h3>
          <p className="text-sm fotn-medium text-bgray-500 dark:text-bgray-50">
            Fund a customer's account.
          </p>
          <form onSubmit={handleSubmit(fundAccount)} className="mt-6">
            <div className="relative flex flex-col mb-6">
              <AsyncSelect
                cacheOptions
                value={state.customer}
                classNames={{
                  control: () =>
                    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-5 w-full h-[56px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
                }}
                onChange={(customer) => {
                  setState({ ...state, customer: customer as any });
                  setValue("customerId", (customer as any).value);
                }}
                loadOptions={lookupCustomers as any}
                defaultOptions
              />
              {errors.customerId?.message && (
                <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                  {errors.customerId.message}
                </p>
              )}
            </div>

            <div className="relative flex flex-col mb-6">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Select an option
              </label>
              <select
                disabled={store.metadata?.addresses ? false : true}
                {...register("currency", { required: true })}
                onChange={(ev) => {
                  setState({ ...state, currency: ev.target.value });
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-5 w-full h-[56px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected>Choose a wallet</option>
                {(store.metadata?.addresses as any) &&
                  (store!.metadata!.addresses! as StoreDepositAddress[]).map(
                    (depositAddress) => (
                      <option value={depositAddress.currency}>
                        {depositAddress.currency} - Balance{" "}
                        {state.customer?.accounts?.find(
                          (account) =>
                            account.tag.indexOf(`:${depositAddress.currency}`) >
                            -1
                        )?.balance ?? 0.0}
                      </option>
                    )
                  )}
              </select>
              {errors.currency?.message && (
                <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                  {errors.currency.message}
                </p>
              )}
            </div>
            <div className="mb-4 ">
              <div className="w-full rounded-lg border border-bgray-200 dark:border-darkblack-400 focus-within:border-success-300 p-4 h-[98px] flex flex-col justify-between">
                <p className="text-sm text-bgray-600 dark:text-darkblack-300 font-medium">
                  Enter amount
                </p>
                <div className="w-full h-[35px] flex justify-between items-center">
                  <span className="text-2xl mr-4 text-bgray-900 dark:text-white font-bold">
                    {state.currency ?? "USDT"}
                  </span>
                  <label className="w-full">
                    <input
                      className="focus:outline-none w-full p-0 focus:ring-0 border-none text-2xl font-bold text-bgray-900 dark:bg-darkblack-600 dark:text-white"
                      {...register("amount", { required: true })}
                    />
                  </label>
                </div>
              </div>
              {errors.amount?.message && (
                <p className="text-sm text-red-400 dark:text-red-400 font-medium pb-4 pl-4">
                  {errors.amount.message}
                </p>
              )}
            </div>

            <div className="flex justify-end">
              <button
                onClick={handleSubmit(fundAccount)}
                className="text-sm bg-success-300 hover:bg-success-400 transition-all py-3 px-4 text-white font-semibold rounded-lg hover:bg-opacity-100"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="mx-auto pt-10 hidden xl:block">
          <img src="/images/illustration/reset-password.svg" alt="" />
        </div>
      </div>
    </>
  );
};
export default FundCustomerPage;
